<template>
  <FlatPickr
    :modelValue="props.filter.value"
    :class="['flatpickr-vue', 'w-full', 'form-control']"
    :config="config"
    @update:modelValue="onUpdateModelValue"
  />
</template>

<script setup lang="ts">
interface _VTI_TYPE_DataTableFilterMetaData {
    /**
     * Filter value
     */
    value: any;
    /**
     * Filter match mode
     */
    matchMode: 'startsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'dateIs' | 'dateIsNot' | 'dateBefore' | 'dateAfter' | string | undefined;
}
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}
interface ColumnFilterProps {
  collectionName: string;
  column: _VTI_TYPE_Column;
  filter: _VTI_TYPE_DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}
  import { ref } from 'vue'
  import dayjs from 'dayjs'
  import FlatPickr from 'vue-flatpickr-component'
  
  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();
  const onUpdateModelValue = (newData: string | null): void => {
    if (!newData) return;
    const updatedData = dayjs(newData).toISOString();
    emit("update:filter", {
      filterName: props.column.name,
      data: updatedData,
    });
  };
  const config = ref<Partial<import("flatpickr/dist/types/options").BaseOptions>>({
    defaultHour: 0,
    defaultMinute: 0,
    enableTime: true,
    disableMobile: false,
    mode: "single", //
  });
</script>

<style scoped></style>

